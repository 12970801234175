export const Airport = () => import('../../components/Airport.vue' /* webpackChunkName: "components/airport" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const AutocompleteAircompanies = () => import('../../components/AutocompleteAircompanies.vue' /* webpackChunkName: "components/autocomplete-aircompanies" */).then(c => wrapFunctional(c.default || c))
export const AutocompleteAirports = () => import('../../components/AutocompleteAirports.vue' /* webpackChunkName: "components/autocomplete-airports" */).then(c => wrapFunctional(c.default || c))
export const BgGray = () => import('../../components/BgGray.vue' /* webpackChunkName: "components/bg-gray" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const ClaimsList = () => import('../../components/ClaimsList.vue' /* webpackChunkName: "components/claims-list" */).then(c => wrapFunctional(c.default || c))
export const ContentColumns = () => import('../../components/ContentColumns.vue' /* webpackChunkName: "components/content-columns" */).then(c => wrapFunctional(c.default || c))
export const Currency = () => import('../../components/Currency.vue' /* webpackChunkName: "components/currency" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../components/Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogFiscalCode = () => import('../../components/DialogFiscalCode.vue' /* webpackChunkName: "components/dialog-fiscal-code" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const Features = () => import('../../components/Features.vue' /* webpackChunkName: "components/features" */).then(c => wrapFunctional(c.default || c))
export const FlightDestinations = () => import('../../components/FlightDestinations.vue' /* webpackChunkName: "components/flight-destinations" */).then(c => wrapFunctional(c.default || c))
export const FlightLastSearches = () => import('../../components/FlightLastSearches.vue' /* webpackChunkName: "components/flight-last-searches" */).then(c => wrapFunctional(c.default || c))
export const FlightOffersRouteWeekend = () => import('../../components/FlightOffersRouteWeekend.vue' /* webpackChunkName: "components/flight-offers-route-weekend" */).then(c => wrapFunctional(c.default || c))
export const FlightPriceChartRoute = () => import('../../components/FlightPriceChartRoute.vue' /* webpackChunkName: "components/flight-price-chart-route" */).then(c => wrapFunctional(c.default || c))
export const FooterFull = () => import('../../components/FooterFull.vue' /* webpackChunkName: "components/footer-full" */).then(c => wrapFunctional(c.default || c))
export const FormSearch = () => import('../../components/FormSearch.vue' /* webpackChunkName: "components/form-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderFull = () => import('../../components/HeaderFull.vue' /* webpackChunkName: "components/header-full" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogged = () => import('../../components/HeaderLogged.vue' /* webpackChunkName: "components/header-logged" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MenuLogged = () => import('../../components/MenuLogged.vue' /* webpackChunkName: "components/menu-logged" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Page404 = () => import('../../components/Page404.vue' /* webpackChunkName: "components/page404" */).then(c => wrapFunctional(c.default || c))
export const PreviewSearch = () => import('../../components/PreviewSearch.vue' /* webpackChunkName: "components/preview-search" */).then(c => wrapFunctional(c.default || c))
export const PromoContent = () => import('../../components/PromoContent.vue' /* webpackChunkName: "components/promo-content" */).then(c => wrapFunctional(c.default || c))
export const QuickContentMenu = () => import('../../components/QuickContentMenu.vue' /* webpackChunkName: "components/quick-content-menu" */).then(c => wrapFunctional(c.default || c))
export const ReviewsWidget = () => import('../../components/ReviewsWidget.vue' /* webpackChunkName: "components/reviews-widget" */).then(c => wrapFunctional(c.default || c))
export const SeoLinks = () => import('../../components/SeoLinks.vue' /* webpackChunkName: "components/seo-links" */).then(c => wrapFunctional(c.default || c))
export const Services = () => import('../../components/Services.vue' /* webpackChunkName: "components/services" */).then(c => wrapFunctional(c.default || c))
export const SocialBox = () => import('../../components/SocialBox.vue' /* webpackChunkName: "components/social-box" */).then(c => wrapFunctional(c.default || c))
export const ClaimAircompany = () => import('../../components/Claim/Aircompany.vue' /* webpackChunkName: "components/claim-aircompany" */).then(c => wrapFunctional(c.default || c))
export const ClaimAirport = () => import('../../components/Claim/Airport.vue' /* webpackChunkName: "components/claim-airport" */).then(c => wrapFunctional(c.default || c))
export const ClaimAnticipated = () => import('../../components/Claim/Anticipated.vue' /* webpackChunkName: "components/claim-anticipated" */).then(c => wrapFunctional(c.default || c))
export const ClaimBankAccountComplete = () => import('../../components/Claim/BankAccountComplete.vue' /* webpackChunkName: "components/claim-bank-account-complete" */).then(c => wrapFunctional(c.default || c))
export const ClaimBasedOnDocuments = () => import('../../components/Claim/BasedOnDocuments.vue' /* webpackChunkName: "components/claim-based-on-documents" */).then(c => wrapFunctional(c.default || c))
export const ClaimBasedOnType = () => import('../../components/Claim/BasedOnType.vue' /* webpackChunkName: "components/claim-based-on-type" */).then(c => wrapFunctional(c.default || c))
export const ClaimBookingReference = () => import('../../components/Claim/BookingReference.vue' /* webpackChunkName: "components/claim-booking-reference" */).then(c => wrapFunctional(c.default || c))
export const ClaimBoughtProducts = () => import('../../components/Claim/BoughtProducts.vue' /* webpackChunkName: "components/claim-bought-products" */).then(c => wrapFunctional(c.default || c))
export const ClaimCancellationDelay = () => import('../../components/Claim/CancellationDelay.vue' /* webpackChunkName: "components/claim-cancellation-delay" */).then(c => wrapFunctional(c.default || c))
export const ClaimCancellationNotification = () => import('../../components/Claim/CancellationNotification.vue' /* webpackChunkName: "components/claim-cancellation-notification" */).then(c => wrapFunctional(c.default || c))
export const ClaimCancellationReason = () => import('../../components/Claim/CancellationReason.vue' /* webpackChunkName: "components/claim-cancellation-reason" */).then(c => wrapFunctional(c.default || c))
export const ClaimComplete = () => import('../../components/Claim/ClaimComplete.vue' /* webpackChunkName: "components/claim-complete" */).then(c => wrapFunctional(c.default || c))
export const ClaimCompensationOffered = () => import('../../components/Claim/CompensationOffered.vue' /* webpackChunkName: "components/claim-compensation-offered" */).then(c => wrapFunctional(c.default || c))
export const ClaimCompensationOfferedInformation = () => import('../../components/Claim/CompensationOfferedInformation.vue' /* webpackChunkName: "components/claim-compensation-offered-information" */).then(c => wrapFunctional(c.default || c))
export const ClaimDateOfTravel = () => import('../../components/Claim/DateOfTravel.vue' /* webpackChunkName: "components/claim-date-of-travel" */).then(c => wrapFunctional(c.default || c))
export const ClaimDelay = () => import('../../components/Claim/Delay.vue' /* webpackChunkName: "components/claim-delay" */).then(c => wrapFunctional(c.default || c))
export const ClaimDepartureDestination = () => import('../../components/Claim/DepartureDestination.vue' /* webpackChunkName: "components/claim-departure-destination" */).then(c => wrapFunctional(c.default || c))
export const ClaimDocumentsComplete = () => import('../../components/Claim/DocumentsComplete.vue' /* webpackChunkName: "components/claim-documents-complete" */).then(c => wrapFunctional(c.default || c))
export const ClaimElement = () => import('../../components/Claim/Element.vue' /* webpackChunkName: "components/claim-element" */).then(c => wrapFunctional(c.default || c))
export const ClaimExplainReason = () => import('../../components/Claim/ExplainReason.vue' /* webpackChunkName: "components/claim-explain-reason" */).then(c => wrapFunctional(c.default || c))
export const ClaimExplanation = () => import('../../components/Claim/Explanation.vue' /* webpackChunkName: "components/claim-explanation" */).then(c => wrapFunctional(c.default || c))
export const ClaimFlightCode = () => import('../../components/Claim/FlightCode.vue' /* webpackChunkName: "components/claim-flight-code" */).then(c => wrapFunctional(c.default || c))
export const ClaimForm = () => import('../../components/Claim/Form.vue' /* webpackChunkName: "components/claim-form" */).then(c => wrapFunctional(c.default || c))
export const ClaimFormLegalAction = () => import('../../components/Claim/FormLegalAction.vue' /* webpackChunkName: "components/claim-form-legal-action" */).then(c => wrapFunctional(c.default || c))
export const ClaimIBan2 = () => import('../../components/Claim/IBan2.vue' /* webpackChunkName: "components/claim-i-ban2" */).then(c => wrapFunctional(c.default || c))
export const ClaimIBanComplete = () => import('../../components/Claim/IBanComplete.vue' /* webpackChunkName: "components/claim-i-ban-complete" */).then(c => wrapFunctional(c.default || c))
export const ClaimIban = () => import('../../components/Claim/Iban.vue' /* webpackChunkName: "components/claim-iban" */).then(c => wrapFunctional(c.default || c))
export const ClaimLegalActionComplete = () => import('../../components/Claim/LegalActionComplete.vue' /* webpackChunkName: "components/claim-legal-action-complete" */).then(c => wrapFunctional(c.default || c))
export const ClaimLuggageProblem = () => import('../../components/Claim/LuggageProblem.vue' /* webpackChunkName: "components/claim-luggage-problem" */).then(c => wrapFunctional(c.default || c))
export const ClaimNamePassengers = () => import('../../components/Claim/NamePassengers.vue' /* webpackChunkName: "components/claim-name-passengers" */).then(c => wrapFunctional(c.default || c))
export const ClaimOtherInformation = () => import('../../components/Claim/OtherInformation.vue' /* webpackChunkName: "components/claim-other-information" */).then(c => wrapFunctional(c.default || c))
export const ClaimOverbookingCompensationAirport = () => import('../../components/Claim/OverbookingCompensationAirport.vue' /* webpackChunkName: "components/claim-overbooking-compensation-airport" */).then(c => wrapFunctional(c.default || c))
export const ClaimOverbookingCompensationAirportInformation = () => import('../../components/Claim/OverbookingCompensationAirportInformation.vue' /* webpackChunkName: "components/claim-overbooking-compensation-airport-information" */).then(c => wrapFunctional(c.default || c))
export const ClaimOverbookingConfirmation = () => import('../../components/Claim/OverbookingConfirmation.vue' /* webpackChunkName: "components/claim-overbooking-confirmation" */).then(c => wrapFunctional(c.default || c))
export const ClaimOverbookingReason = () => import('../../components/Claim/OverbookingReason.vue' /* webpackChunkName: "components/claim-overbooking-reason" */).then(c => wrapFunctional(c.default || c))
export const ClaimPassengersExtended = () => import('../../components/Claim/PassengersExtended.vue' /* webpackChunkName: "components/claim-passengers-extended" */).then(c => wrapFunctional(c.default || c))
export const ClaimProblem = () => import('../../components/Claim/Problem.vue' /* webpackChunkName: "components/claim-problem" */).then(c => wrapFunctional(c.default || c))
export const ClaimReason = () => import('../../components/Claim/Reason.vue' /* webpackChunkName: "components/claim-reason" */).then(c => wrapFunctional(c.default || c))
export const ClaimRebooked = () => import('../../components/Claim/Rebooked.vue' /* webpackChunkName: "components/claim-rebooked" */).then(c => wrapFunctional(c.default || c))
export const ClaimReportAirport = () => import('../../components/Claim/ReportAirport.vue' /* webpackChunkName: "components/claim-report-airport" */).then(c => wrapFunctional(c.default || c))
export const ClaimRow50 = () => import('../../components/Claim/Row50.vue' /* webpackChunkName: "components/claim-row50" */).then(c => wrapFunctional(c.default || c))
export const ClaimSignComplete = () => import('../../components/Claim/SignComplete.vue' /* webpackChunkName: "components/claim-sign-complete" */).then(c => wrapFunctional(c.default || c))
export const ClaimSignContract = () => import('../../components/Claim/SignContract.vue' /* webpackChunkName: "components/claim-sign-contract" */).then(c => wrapFunctional(c.default || c))
export const ClaimSignContractAlternative = () => import('../../components/Claim/SignContractAlternative.vue' /* webpackChunkName: "components/claim-sign-contract-alternative" */).then(c => wrapFunctional(c.default || c))
export const ClaimSignLegalAction = () => import('../../components/Claim/SignLegalAction.vue' /* webpackChunkName: "components/claim-sign-legal-action" */).then(c => wrapFunctional(c.default || c))
export const ClaimSummaryClaim = () => import('../../components/Claim/SummaryClaim.vue' /* webpackChunkName: "components/claim-summary-claim" */).then(c => wrapFunctional(c.default || c))
export const ClaimUploadDocuments = () => import('../../components/Claim/UploadDocuments.vue' /* webpackChunkName: "components/claim-upload-documents" */).then(c => wrapFunctional(c.default || c))
export const ClaimUploadPersonalDocuments = () => import('../../components/Claim/UploadPersonalDocuments.vue' /* webpackChunkName: "components/claim-upload-personal-documents" */).then(c => wrapFunctional(c.default || c))
export const HelpContact = () => import('../../components/Help/Contact.vue' /* webpackChunkName: "components/help-contact" */).then(c => wrapFunctional(c.default || c))
export const HelpHeader = () => import('../../components/Help/HelpHeader.vue' /* webpackChunkName: "components/help-header" */).then(c => wrapFunctional(c.default || c))
export const HelpMenu = () => import('../../components/Help/HelpMenu.vue' /* webpackChunkName: "components/help-menu" */).then(c => wrapFunctional(c.default || c))
export const InputCountry = () => import('../../components/Input/Country.vue' /* webpackChunkName: "components/input-country" */).then(c => wrapFunctional(c.default || c))
export const InputFile = () => import('../../components/Input/InputFile.vue' /* webpackChunkName: "components/input-file" */).then(c => wrapFunctional(c.default || c))
export const InputSelect = () => import('../../components/Input/InputSelect.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c))
export const InputPinch = () => import('../../components/Input/Pinch.vue' /* webpackChunkName: "components/input-pinch" */).then(c => wrapFunctional(c.default || c))
export const InputRating = () => import('../../components/Input/Rating.vue' /* webpackChunkName: "components/input-rating" */).then(c => wrapFunctional(c.default || c))
export const Layout2Card2 = () => import('../../components/Layout2/Card2.vue' /* webpackChunkName: "components/layout2-card2" */).then(c => wrapFunctional(c.default || c))
export const Layout2Card2Content = () => import('../../components/Layout2/Card2Content.vue' /* webpackChunkName: "components/layout2-card2-content" */).then(c => wrapFunctional(c.default || c))
export const Layout2Accordion = () => import('../../components/Layout2/Layout2Accordion.vue' /* webpackChunkName: "components/layout2-accordion" */).then(c => wrapFunctional(c.default || c))
export const Layout2Aircompany = () => import('../../components/Layout2/Layout2Aircompany.vue' /* webpackChunkName: "components/layout2-aircompany" */).then(c => wrapFunctional(c.default || c))
export const Layout2AircompanyQuickSelection = () => import('../../components/Layout2/Layout2AircompanyQuickSelection.vue' /* webpackChunkName: "components/layout2-aircompany-quick-selection" */).then(c => wrapFunctional(c.default || c))
export const Layout2Airport = () => import('../../components/Layout2/Layout2Airport.vue' /* webpackChunkName: "components/layout2-airport" */).then(c => wrapFunctional(c.default || c))
export const Layout2AirportQuickSelection = () => import('../../components/Layout2/Layout2AirportQuickSelection.vue' /* webpackChunkName: "components/layout2-airport-quick-selection" */).then(c => wrapFunctional(c.default || c))
export const Layout2Alert = () => import('../../components/Layout2/Layout2Alert.vue' /* webpackChunkName: "components/layout2-alert" */).then(c => wrapFunctional(c.default || c))
export const Layout2Amount = () => import('../../components/Layout2/Layout2Amount.vue' /* webpackChunkName: "components/layout2-amount" */).then(c => wrapFunctional(c.default || c))
export const Layout2AutocompleteAddress = () => import('../../components/Layout2/Layout2AutocompleteAddress.vue' /* webpackChunkName: "components/layout2-autocomplete-address" */).then(c => wrapFunctional(c.default || c))
export const Layout2AutocompleteFaq = () => import('../../components/Layout2/Layout2AutocompleteFaq.vue' /* webpackChunkName: "components/layout2-autocomplete-faq" */).then(c => wrapFunctional(c.default || c))
export const Layout2Button = () => import('../../components/Layout2/Layout2Button.vue' /* webpackChunkName: "components/layout2-button" */).then(c => wrapFunctional(c.default || c))
export const Layout2Checkbox = () => import('../../components/Layout2/Layout2Checkbox.vue' /* webpackChunkName: "components/layout2-checkbox" */).then(c => wrapFunctional(c.default || c))
export const Layout2ClaimUpload = () => import('../../components/Layout2/Layout2ClaimUpload.vue' /* webpackChunkName: "components/layout2-claim-upload" */).then(c => wrapFunctional(c.default || c))
export const Layout2Container = () => import('../../components/Layout2/Layout2Container.vue' /* webpackChunkName: "components/layout2-container" */).then(c => wrapFunctional(c.default || c))
export const Layout2Country = () => import('../../components/Layout2/Layout2Country.vue' /* webpackChunkName: "components/layout2-country" */).then(c => wrapFunctional(c.default || c))
export const Layout2Date = () => import('../../components/Layout2/Layout2Date.vue' /* webpackChunkName: "components/layout2-date" */).then(c => wrapFunctional(c.default || c))
export const Layout2DialogSide = () => import('../../components/Layout2/Layout2DialogSide.vue' /* webpackChunkName: "components/layout2-dialog-side" */).then(c => wrapFunctional(c.default || c))
export const Layout2FileButton = () => import('../../components/Layout2/Layout2FileButton.vue' /* webpackChunkName: "components/layout2-file-button" */).then(c => wrapFunctional(c.default || c))
export const Layout2FlightNumber = () => import('../../components/Layout2/Layout2FlightNumber.vue' /* webpackChunkName: "components/layout2-flight-number" */).then(c => wrapFunctional(c.default || c))
export const Layout2IconButton = () => import('../../components/Layout2/Layout2IconButton.vue' /* webpackChunkName: "components/layout2-icon-button" */).then(c => wrapFunctional(c.default || c))
export const Layout2Input = () => import('../../components/Layout2/Layout2Input.vue' /* webpackChunkName: "components/layout2-input" */).then(c => wrapFunctional(c.default || c))
export const Layout2Label = () => import('../../components/Layout2/Layout2Label.vue' /* webpackChunkName: "components/layout2-label" */).then(c => wrapFunctional(c.default || c))
export const Layout2PassengersTable = () => import('../../components/Layout2/Layout2PassengersTable.vue' /* webpackChunkName: "components/layout2-passengers-table" */).then(c => wrapFunctional(c.default || c))
export const Layout2Phone = () => import('../../components/Layout2/Layout2Phone.vue' /* webpackChunkName: "components/layout2-phone" */).then(c => wrapFunctional(c.default || c))
export const Layout2PhonePrefix = () => import('../../components/Layout2/Layout2PhonePrefix.vue' /* webpackChunkName: "components/layout2-phone-prefix" */).then(c => wrapFunctional(c.default || c))
export const Layout2Radio = () => import('../../components/Layout2/Layout2Radio.vue' /* webpackChunkName: "components/layout2-radio" */).then(c => wrapFunctional(c.default || c))
export const Layout2RadioGroup = () => import('../../components/Layout2/Layout2RadioGroup.vue' /* webpackChunkName: "components/layout2-radio-group" */).then(c => wrapFunctional(c.default || c))
export const Layout2Row = () => import('../../components/Layout2/Layout2Row.vue' /* webpackChunkName: "components/layout2-row" */).then(c => wrapFunctional(c.default || c))
export const Layout2Select = () => import('../../components/Layout2/Layout2Select.vue' /* webpackChunkName: "components/layout2-select" */).then(c => wrapFunctional(c.default || c))
export const Layout2ShowIfNotVisible = () => import('../../components/Layout2/Layout2ShowIfNotVisible.vue' /* webpackChunkName: "components/layout2-show-if-not-visible" */).then(c => wrapFunctional(c.default || c))
export const Layout2Signature = () => import('../../components/Layout2/Layout2Signature.vue' /* webpackChunkName: "components/layout2-signature" */).then(c => wrapFunctional(c.default || c))
export const Layout2Text = () => import('../../components/Layout2/Layout2Text.vue' /* webpackChunkName: "components/layout2-text" */).then(c => wrapFunctional(c.default || c))
export const Layout2Textarea = () => import('../../components/Layout2/Layout2Textarea.vue' /* webpackChunkName: "components/layout2-textarea" */).then(c => wrapFunctional(c.default || c))
export const Layout2Title = () => import('../../components/Layout2/Layout2Title.vue' /* webpackChunkName: "components/layout2-title" */).then(c => wrapFunctional(c.default || c))
export const Layout2Upload = () => import('../../components/Layout2/Layout2Upload.vue' /* webpackChunkName: "components/layout2-upload" */).then(c => wrapFunctional(c.default || c))
export const Layout2Validation = () => import('../../components/Layout2/Layout2Validation.vue' /* webpackChunkName: "components/layout2-validation" */).then(c => wrapFunctional(c.default || c))
export const PagesPage404 = () => import('../../components/Pages/Page404.vue' /* webpackChunkName: "components/pages-page404" */).then(c => wrapFunctional(c.default || c))
export const PagesPageClaimType = () => import('../../components/Pages/PageClaimType.vue' /* webpackChunkName: "components/pages-page-claim-type" */).then(c => wrapFunctional(c.default || c))
export const PagesPageInformation = () => import('../../components/Pages/PageInformation.vue' /* webpackChunkName: "components/pages-page-information" */).then(c => wrapFunctional(c.default || c))
export const PagesPageStandard = () => import('../../components/Pages/PageStandard.vue' /* webpackChunkName: "components/pages-page-standard" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliate = () => import('../../components/User/Affiliate.vue' /* webpackChunkName: "components/user-affiliate" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliateClaim = () => import('../../components/User/AffiliateClaim.vue' /* webpackChunkName: "components/user-affiliate-claim" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliateNoResults = () => import('../../components/User/AffiliateNoResults.vue' /* webpackChunkName: "components/user-affiliate-no-results" */).then(c => wrapFunctional(c.default || c))
export const UserAuth = () => import('../../components/User/Auth.vue' /* webpackChunkName: "components/user-auth" */).then(c => wrapFunctional(c.default || c))
export const UserAuthLayout = () => import('../../components/User/AuthLayout.vue' /* webpackChunkName: "components/user-auth-layout" */).then(c => wrapFunctional(c.default || c))
export const UserDialogUserDevice = () => import('../../components/User/DialogUserDevice.vue' /* webpackChunkName: "components/user-dialog-user-device" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliateLink = () => import('../../components/User/Affiliate/AffiliateLink.vue' /* webpackChunkName: "components/user-affiliate-link" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliateBanners = () => import('../../components/User/Affiliate/Banners.vue' /* webpackChunkName: "components/user-affiliate-banners" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliateHeader = () => import('../../components/User/Affiliate/Header.vue' /* webpackChunkName: "components/user-affiliate-header" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliatePaymentHistory = () => import('../../components/User/Affiliate/PaymentHistory.vue' /* webpackChunkName: "components/user-affiliate-payment-history" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliatePaymentMethod = () => import('../../components/User/Affiliate/PaymentMethod.vue' /* webpackChunkName: "components/user-affiliate-payment-method" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliatePaymentRequest = () => import('../../components/User/Affiliate/PaymentRequest.vue' /* webpackChunkName: "components/user-affiliate-payment-request" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliateReferFriendActivate = () => import('../../components/User/Affiliate/ReferFriendActivate.vue' /* webpackChunkName: "components/user-affiliate-refer-friend-activate" */).then(c => wrapFunctional(c.default || c))
export const UserAffiliateStatistics = () => import('../../components/User/Affiliate/Statistics.vue' /* webpackChunkName: "components/user-affiliate-statistics" */).then(c => wrapFunctional(c.default || c))
export const SeoAircompanyStandard = () => import('../../components/Seo/Aircompany/Standard.vue' /* webpackChunkName: "components/seo-aircompany-standard" */).then(c => wrapFunctional(c.default || c))
export const UserTravelAgencyClaimList = () => import('../../components/User/TravelAgency/ClaimList.vue' /* webpackChunkName: "components/user-travel-agency-claim-list" */).then(c => wrapFunctional(c.default || c))
export const UserTravelAgencyHeader = () => import('../../components/User/TravelAgency/TravelAgencyHeader.vue' /* webpackChunkName: "components/user-travel-agency-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
